#
# Summer Workshop Scripts
#

$(document).ready ->
	# AOS
	AOS.init startEvent: 'load'

	#
	# Article Closing Mark
	#
	$last_element = $(".single-workshop_article article").children("p").last()
	$last_element.append '<span class="end-of-article"></span>'

	#
	# Search Bar
	#
	$('.workshops-search-btn').click ->
		$('.workshops-header .search-box').addClass('search-open')
		$('.search-input').focus()
	$('.close-search').click ->
		$('.workshops-header .search-box').removeClass('search-open')
		
	#
	# Sub Navigation Toggle
	#
	$('.menu-item-has-children').click ->
		$(this).children('.sub-menu').slideToggle('fast')